<template>
  <div class="alert-details">
    <div class="container">
      <div class="row mt-2" v-if="user != null && !user.verified">
        <div class="col">
          <div class="alert alert-danger" role="alert" v-if="!user.inVerProcess">
            La identidad de este usuario no está verificada
          </div>
          <div class="alert alert-warning" role="alert" v-if="user.inVerProcess">
            La identidad de este usuario está en proceso de verificación
          </div>
        </div>
      </div>
      <div class="row" v-if="user != null && alert != null">
        <div class="col-lg-3 col-md-4 col-sm-5 col-xs-12">
          <!-- Imagen del perfil del usuario -->
          <div class="user-info-container">
            <img class="profile-image mx-auto d-block" :src="user.picture" alt="Imagen de perfil">
            <!-- <img class="profile-image mx-auto d-block" src="https://picsum.photos/200/300" alt="Imagen de perfil"> -->
            <h4 class="mt-2">Nombre</h4>
            <p>{{user.name}} {{user.lastName}}</p>
            <h4>Edad</h4>
            <p>{{user.age}}</p>
            <h4>Teléfono</h4>
            <p>{{user.phone}}</p>
            <h6>Hace aproximadamente</h6>
            <p>{{time}}</p>
          </div>
        </div>
        <div class="col-lg-9 col-md-8 col-sm-7 col-xs-12">
          <div class="row">
            <div class="col"><b>La alerta se inició el</b> {{formatDate(alert.date)}}</div>
            <div class="col"><b>Ubicación actualizada el</b> {{formatDate(alert.lastDate)}}</div>
            <div class="col" v-if="role>=2"><b>Distancia:</b> {{getDist(alert.latitude, alert.longitude)}} km</div>
            <div class="col" v-if="role<2"><b>Coordenadas: </b>{{alert.latitude}}, {{alert.longitude}}</div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <GmapMap
                :center="{
                  lat: alert.latitude,
                  lng: alert.longitude
                }"
                :zoom="13"
                :options="map.options"
                map-type-id="roadmap"
                style="width: 100%; height: 400px"
              >
                <GmapMarker
                  :position="{
                    lat: alert.latitude,
                    lng: alert.longitude
                  }"
                  :clickable="false" :draggable="false"
                ></GmapMarker>
              </GmapMap>
            </div>
          </div>
          <div class="row" v-if="alert.active">
            <div class="col">
              <button @click="requestToSuspend" class="btn btn-outline-danger ml-2 mt-2">Solicitar suspender al usuario</button>
              <button @click="cancelAlert" class="btn btn-outline-danger ml-2 mt-2">Cancelar alerta</button>
              <button @click="completeAlert" class="btn btn-primary ml-2 mt-2">Completar alerta</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlertDetails',
  data() {
    return {
      role: parseInt(localStorage.getItem('role')),
      attend: true,
      map: {
        options: this.$global.mapOptions,
      },
      time: '',
      alert: null,
      user: null,
      deleteAttend: true,
    }
  },
  sockets: {
    locationUpdated(data) {
      const id = data[0]
      // const latitude = data[1]
      // const longitude = data[2]
      if (this.$route.params.id == id) {
        this.getAlert(id)
      }
      this.time = this.timeElapsed(this.alert.date)
    },
    alertStopped(id) {
      if (this.$route.params.id == id) {
        // Notificar que la alerta se ha cancelado por el usuario
        alert('Alerta cancelada por el usuario')
        // go back
        this.$router.go(-1)
      }
    },
  },
  methods: {
    getDist(latitude, longitude) {
      let dist = this.$getDist(
        latitude,
        longitude,
        localStorage.getItem('latitude'),
        localStorage.getItem('longitude')
      );
      return dist;
    },
    formatDate(date) {
      // Formatear fecha a YYYY/MM/DD HH:MM:SS
      let d = new Date(date)
      let month = '' + (d.getMonth() + 1)
      let day = '' + d.getDate()
      let year = d.getFullYear()
      let hours = d.getHours()
      let minutes = d.getMinutes()
      let seconds = d.getSeconds()
      let sufixTime = 'AM'
      if (hours > 12) {
        hours = hours - 12
        sufixTime = 'PM'
      }
      return `${day}/${month}/${year} a las ${hours}:${minutes}:${seconds} ${sufixTime}`
    },
    timeElapsed(date) {
      const seconds = Math.floor((new Date() - new Date(date)) / 1000)
      let interval = Math.floor(seconds / 31536000)
      if (interval > 1) {
        return interval + ' años'
      }
      interval = Math.floor(seconds / 2592000)
      if (interval > 1) {
        return interval + ' meses'
      }
      interval = Math.floor(seconds / 86400)
      if (interval > 1) {
        return interval + ' días'
      }
      interval = Math.floor(seconds / 3600)
      if (interval > 1) {
        return interval + ' horas'
      }
      interval = Math.floor(seconds / 60)
      if (interval > 1) {
        return interval + ' minutos'
      }
      return Math.floor(seconds) + ' segundos'
    },
    getAlert(id) {
      this.axios.get('/api/alerts/details/' + id,
      {
        headers: {
          'Authorization': this.$getAuthorization(),
        },
      })
      .then(response => {
        if (response.data.status == 'error') {
          console.log(response.data.message, response.data.error)
        } else if (response.data.status == 'warning') {
          console.log(response.data.message, response.data.error)
          this.alert = response.data.data
        } else {
          this.alert = response.data.alert;
          this.user = response.data.user;
          this.time = this.timeElapsed(this.alert.date)
          if( this.attend ) {
            this.attend = false;
            this.attendAlert(localStorage.getItem('id'))
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
    cancelAlert() {
      const alert = confirm(`¿Está seguro de cancelar la alerta?\n\nEsta opción no se puede deshacer.\nCancela la alerta SOLO si NO ha sido completada.`);
      if (alert) {
        this.$socket.emit('cancelAlert', this.alert._id)
        this.deleteAttend = false;
        this.$router.go(-1)
      }
    },
    requestToSuspend() {
      const reason = prompt('Al suspender un usuario este no podrá enviar nuevas alertas\n\n¿Por qué desea suspender al usuario?');
      if (reason) {
        const replyMarkup = JSON.stringify({
          inline_keyboard: [[{
            text: 'Ir a la consola',
            url: 'https://consola.zonasegura.org/console?opt=2',
          }]],
        })
        const message =
`El usuario *${this.user.name}* ha solicitado suspenderse
*Razón:*  _${reason}_

*Datos del usuario*
*Nombre:* ${this.user.name}
*Apellidos:* ${this.user.lastName}
*Telefono:* ${this.user.phone}
*Edad:* ${this.user.age}

*La solicitud fue realizada por*
*Nombre:* ${localStorage.getItem('name')}
*Usuario:* ${localStorage.getItem('username')}
_Visita la consola para suspender a este usuario_`
        fetch(`${this.$global.botAlertApi}sendMessage?chat_id=${this.$global.chatAdminsId}&text=${encodeURI(message)}&parse_mode=MarkdownV2&reply_markup=${replyMarkup}`)
        .then(response => {
          if (response.ok == true) {
            const alert = confirm('La solicitud fue enviada, está en proceso de revisión.\n\n¿Desea cancelar la alerta actual?');
            if (alert) {
              this.$socket.emit('cancelAlert', this.alert._id)
              this.deleteAttend = false;
              this.$router.go(-1)
            }
          }
        })
      }
    },
    completeAlert() {
      const alert = confirm(`¿Está seguro de completar la alerta?\n\nEsta opción no se puede deshacer.`);
      if (alert) {
        this.$socket.emit('completeAlert', this.alert._id)
        this.deleteAttend = false;
        this.$router.go(-1)
      }
    },
    attendAlert(adminId) {
      if(this.alert.active) {
        this.axios.put('/api/alerts/attend',
        {
          alertId: this.alert._id,
          adminId
        },
        {
          headers: {
            'Authorization': this.$getAuthorization(),
          },
        })
        .then(response => {
          if (response.data.status == 'success') {
            this.$socket.emit('attendedBy', [this.alert._id, adminId])
          } else {
            console.log(response.data.message, response.data.error)
          }
        })
        .catch(error => {
          console.log(error);
        });
      }
    }
  },
  mounted() {
    this.getAlert(this.$route.params.id)
  },
  beforeDestroy() {
    if (this.deleteAttend) {
      this.attendAlert(null)
    }
  },
}
</script>

<style scoped>
.profile-image {
  height: 200px;
  width: 200px;
  border-radius: 15%;
  object-fit: cover;
}
</style>